@import "../../utils/app.scss";

.search-container {
  display: block;
  min-height: 100vh;

  @media(min-width: 1500px) {
    width: 1200px;
    margin: 40px auto !important;
  }

  @media(max-width: 900px) {
    margin: 10px 5px;
  }

  .search-menu {
    width: 225px;
    margin: 20px auto 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    clear: both;

    .item {
      width: auto;
      padding: 10px 15px;
      height: 50px;
      margin: 0% 1%;
      text-align: center;
      cursor: pointer;
      font-weight: 500;
      color: #000;

      &.active {
        border-bottom: 2px solid $bleu;
      }
    }
  }

  .search-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 0px;
    font-family: $font-family-open-sans;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;

    @media(max-width: $break_tablet) {
      width: 100%;
      flex-wrap: wrap;
    }

    @media(max-width: $break_mobile) {
      width: 100%;
    }
  }
}
