@import "../../utils/app.scss";

.collecte-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 40px 0px;

  @media(min-width: 1500px) {
    width: 1200px;
    margin: 40px auto !important;
  }

  @media(max-width: 900px) {
    margin: 10px 5px;
  }


  .block {
    margin-bottom: 40px;
    background-color: #fff;

    @media(max-width: 900px) {
      display: none;
    }

    &.mobile {
      display: none;
      background-color: #fff !important;

      @media(max-width: 900px) {
        display: block;
      }
    }
  }
}

.collecte-updates {
  width: 100%;

  @media(max-width: $break_tablet) {
    display: none;
  }

  &.mobile {
    display: none;

    @media(max-width: $break_tablet) {
      display: block;
      background-color: #fff;
    }
  }

  .association-update {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px 15px;

    @media(max-width: $break_tablet) {
      width: 100%;
      margin-left: 0%;
    }
  }
}

.en-savoir-plus-association {
  border: 1px solid $bleu;
  color: $bleu;
  font-family: $font-family-pompiere;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 10px 15px;
  width: 220px;
  text-align: center;
}

.mobile-name-collecte {
  display: none;

  @media(max-width: $break_tablet) {
    width: 100%;
    margin: 20px auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  .logo {
    background-size: cover;
    background-position: 50%;
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }

  h2 {
    margin-bottom: 0px;
  }
}

.mobile-menu-collecte {
  display: none;

  @media(max-width: $break_tablet) {
    width: 100%;
    margin: 0px auto 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    overflow-x: scroll;
  }

  .item {
    width: auto;
    padding: 10px 15px;
    height: 50px;
    margin: 0% 1%;
    text-align: center;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid $bleu;
    }
  }
}
