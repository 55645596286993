@import "../../utils/app.scss";

.checkout-container-block {
  display: flex;
  min-height: 100vh;

  @media(max-width: $break_tablet) {
    height: 100%;
    flex-direction: column-reverse;
  }

  &.final {
    height: 100vh !important;
  }

  .checkout-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: 60%;

    @media(max-width: $break_tablet) {
      width: 100% !important;
    }

    &.left-block {
      padding-top: 40px;
      background-color: $gris_clair;

      @media(max-width: $break_tablet) {
        padding: 40px 3px;
      }
    }

    &.final-block {
      flex: 1;
      flex-basis: 100%;
      background: url('../../resources/img/header_bg.png');
      padding: 10px;
      justify-content: center;

      .thank-you {
        background-color: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        padding: 25px 15px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-size: 16px;
          margin: 15px 0;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.3px;
          text-align: center;
        }

        .thank-you-p {
          width: 500px;
          font-weight: 200;

          @media(max-width: $break_mobile) {
            width: 300px;
          }
        }
      }
    }

    .paypal-block {
      display: block;
    }

    &.right-block {
      width: 40%;
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;

      @media(max-width: $break_tablet) {
        position: relative;
      }

      .header {
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #f3f3ff;
        position: relative;

        h2 {
          margin: 0 30px;
          line-height: 80px;
          font-weight: 200;
        }
      }

      .cart-block-checkout {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 90%;
        padding: 30px 0px;
      }

      .cart-item-checkout {
        color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;

        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
          border-radius: 5px;
        }

        p {
          margin-bottom: 0em;
          font-size: 15px;
        }

        .cart-item-prices {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-grow: 1;

          .special {
            margin-right: 20px;
          }

          .total {
            font-weight: 600;
          }
        }
      }

      .cart-total-checkout {
        border-top: 1px solid #f3f3ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;

        .cart-total-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          h3 {
              font-weight: 200;
              color: #fff;
          }

          h1 {
            font-weight: 200;
            color: #fff;
          }
        }
      }

      a {
        display: none;
      }
    }
  }
}
