@import "../../utils/app.scss";

.loginContainer {
  background-position: 20% 50%;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  .logo {
    width: 175px;
    margin: 30px 0px;
  }

  a {
    color: #fff;
  }

  .loginActivationMessage {
    color: #fff;
    text-align: center;
    padding: 15px 20px;
    width: 300px;
    border-radius: 5px;
  }

  .loginContainerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 15px 20px;

    .association-question {
      font-weight: 200;

      a {
        text-decoration: underline;
        color: #fff;
        font-weight: 400;
        letter-spacing: 0.3px;
      }
    }

    .loginSignupBox {
      background-color: #fff;
      border-radius: 5px;
      padding: 10px 15px;
    }

    .retour-au-site {
      font-weight: 200;
      text-align: center;

      a {
        color: $bleu;
      }
    }
  }
}
